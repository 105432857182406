<template>
  <div class="auth-wrapper auth-v2">
    <div class="wrapper__app-bar-i18n">
      <app-bar-i18n></app-bar-i18n>
    </div>

    <div class="auth-inner">
      <GeneralLogo />

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="
                require(`@/assets/images/misc/mask-v2-${
                  $vuetify.theme.dark ? 'dark' : 'light'
                }.png`)
              "
            />
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    {{ t("ForgotPass") }} 🔒
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form ref="forgotForm" @submit.prevent="handleFormSubmit">
                    <FormTextField
                      v-model="formData.email"
                      :label="t('Email')"
                      :placeholder="t('Email')"
                      :rules="[validators.required, validators.emailValidator]"
                      class="mb-6"
                      @onChangeInput="(v) => onChangeFormInputs(v, 'email')"
                    />

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      :loading="loading"
                      :disabled="loading"
                    >
                      {{ t("GetItBack") }}
                    </v-btn>
                  </v-form>
                </v-card-text>

                <v-card-actions class="d-flex justify-center align-center">
                  <router-link
                    :to="{ name: 'auth-login' }"
                    class="d-flex align-center text-sm"
                  >
                    <v-icon size="24" color="primary">
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>{{ t("Back") }}</span>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import GeneralLogo from "@/components/general/Logo";
import AppBarI18n from "@core/layouts/components/app-bar/AppBarI18n";
import FormTextField from "@/components/forms/TextField";

import { mdiChevronLeft } from "@mdi/js";
import {
  ref,
  reactive,
  onUpdated,
  getCurrentInstance,
} from "@vue/composition-api";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import { required, emailValidator } from "@core/utils/validation";
import api from "@/services";

export default {
  components: {
    GeneralLogo,
    AppBarI18n,
    FormTextField,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const { t } = useUtilsI18n();
    const loading = ref(false);

    const forgotForm = ref(null);
    const formData = reactive({
      email: null,
    });
    const forgotPassError = ref(t("ForgotPassError"));
    const forgotPassSendMail = ref(t("ForgotPassSendMail"));

    const onChangeFormInputs = (value, property) => {
      formData[property] = value;
    };

    const onToggleLoading = () => {
      loading.value = !loading.value;
    };

    const handleFormSubmit = async () => {
      const isFormValid = forgotForm.value.validate();
      if (!isFormValid) return;

      onToggleLoading();

      try {
        await api.forgotPassword(formData);
        vm.$alert(forgotPassSendMail.value, null, "success");
      } catch (error) {
        vm.$alert(forgotPassError.value, null, "error");
      }

      onToggleLoading();
      forgotForm.value.reset();
    };

    onUpdated(() => {
      forgotPassError.value = t("ForgotPassError");
      forgotPassSendMail.value = t("ForgotPassSendMail");
    });

    return {
      forgotForm,
      formData,
      loading,

      validators: {
        required,
        emailValidator,
      },
      icons: {
        mdiChevronLeft,
      },

      onChangeFormInputs,
      handleFormSubmit,
      t,
    };
  },
};
</script>

<style lang="scss">
@import "@core/preset/preset/pages/auth.scss";
</style>
